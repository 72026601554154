import { useIntl } from 'react-intl';

import { Button, ButtonTypes } from '@/components/Button';
import { Layout } from '@/components/Layout';
import { homeRoute } from '@/utils/routes';

export default function Custom404() {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <div className="grid-no-result">
        <div>
          <h1 className="title-h2">{formatMessage({ id: '404.title' })}</h1>
          <Button link={homeRoute} type={ButtonTypes.Button} style="primary">
            {formatMessage({ id: '404.cta' })}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
